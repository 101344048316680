import React, { BaseSyntheticEvent, ReactFragment, useState } from "react";
import { PageHeader } from "./PageHeader";

interface QuestionProps {
  question: ReactFragment;
  answer: ReactFragment;
  expanded: boolean;
  onClick(): void;
}

const ExpandableQuestion: React.FC<QuestionProps> = ({
  question,
  answer,
  expanded,
  onClick,
}) => {
  function handleClickQuestion(e: BaseSyntheticEvent): void {
    e.preventDefault();
    onClick();
  }
  return (
    <div className="tos_qa_block">
      <a href="#" onClick={handleClickQuestion}>
        <p
          className={`tos_qa_q tos_exp_hdr ${
            expanded ? "tos_expander_top_open" : "tos_expander_top_closed"
          }`}
        >
          <span>Q: {question}</span>
        </p>
      </a>
      {expanded && (
        <p className="tos_qa_a tos_expander_btm_open">
          <span>A: {answer}</span>
        </p>
      )}
    </div>
  );
};

export const Faq: React.FC = () => {
  const [expandedQuestion, setExpandedQuestion] = useState<string>();
  function handleClickQuestion(id: string) {
    return function () {
      setExpandedQuestion((currentExpandedQuestion) =>
        currentExpandedQuestion === id ? undefined : id
      );
    };
  }

  return (
    <>
      <PageHeader
        primary="Questions and Answers"
        secondary="Answers to commonly asked questions regarding our process"
      />
      <div className="content_set_team">
        <div className="tos_qa_expander">
          <ExpandableQuestion
            question={<>Which browsers can I use with the client SPA?</>}
            answer={
              <>
                We develop and test our solution with the Chrome browser. Other
                browsers may also be compatible, but we haven't tested them
                extensively.
              </>
            }
            onClick={handleClickQuestion("spa1")}
            expanded={expandedQuestion === "spa1"}
          />
          <ExpandableQuestion
            question={<>Do I get the source to the client SPA?</>}
            answer={
              <>
                No. You can however use the React components that we created for
                the Twin Oak SPA.
              </>
            }
            onClick={handleClickQuestion("spa2")}
            expanded={expandedQuestion === "spa2"}
          />
          <ExpandableQuestion
            question={<>Can I replace the client SPA with one of my own?</>}
            answer={
              <>
                Yes. Our backend solution can be accessed by a different SPA
                client that you create. You could create an alternate SPA client
                using your own technology or use the React components that we
                created for the Twin Oak SPA.
              </>
            }
            onClick={handleClickQuestion("spa3")}
            expanded={expandedQuestion === "spa3"}
          />
          <ExpandableQuestion
            question={<>Am I required to run on the Azure cloud platform?</>}
            answer={
              <>
                No. Although our original hosting target was the Azure cloud,
                our translated solution also runs on an on-premises Windows
                Server. This only requires configuration file changes: the code
                stays the same and the basic architecture using a SPA and
                WebAPIs stays the same. We also have a build and deployment
                pipeline that target an on-premises server.
              </>
            }
            onClick={handleClickQuestion("hosting1")}
            expanded={expandedQuestion === "hosting1"}
          />
          <ExpandableQuestion
            question={<>Do I get the source code to the backend APIs?</>}
            answer={
              <>
                No. The API source is proprietary, but you do own all of the
                code translated from your original source. You can also extend
                the web API for the backend by adding your own web APIs to the
                solution using our extension points and you would of course own
                that code.
              </>
            }
            onClick={handleClickQuestion("hosting3")}
            expanded={expandedQuestion === "hosting3"}
          />

          <ExpandableQuestion
            question={
              <>Why generate modern code that resembles the old code?</>
            }
            answer={
              <>
                We preserve style of generated code so existing developers have
                a familiar view of the code, enabling them to learn the new
                technology easier or transfer their knowledge to modern
                developers.
              </>
            }
            onClick={handleClickQuestion("gen1")}
            expanded={expandedQuestion === "gen1"}
          />
          <ExpandableQuestion
            question={<>Why translate to a PLC-like runtime?</>}
            answer={
              <>
                Keeping the code structured around a PLC-like runtime preserves
                most of the business knowledge and program logic accumulated
                over years (or decades) of development without large-scale
                disruptive changes.
              </>
            }
            onClick={handleClickQuestion("gen2")}
            expanded={expandedQuestion === "gen2"}
          />
          <ExpandableQuestion
            question={
              <>What is a "procedural state machine" and why do I need one?</>
            }
            answer={
              <>
                The user interaction model for the web differs from the
                AS400/RPG, so by converting your procedures into state machines
                we can manage these differences. Think of each interaction with
                the end user as a new state and these need to be tracked and
                managed. The sequence of operations and interactions is
                maintained as the SPA directs execution through the steps in
                your procedures.
              </>
            }
            onClick={handleClickQuestion("gen3")}
            expanded={expandedQuestion === "gen3"}
          />
          <ExpandableQuestion
            question={<>How "good" is the translation?</>}
            answer={
              <>
                For our most recent customer we translated 1200 programs with
                99% success rate. We translated 800 procedures with an 85%
                success rate. We also translated 100% of the data files, menus,
                forms, and DFU definitions.
              </>
            }
            onClick={handleClickQuestion("gen4")}
            expanded={expandedQuestion === "gen4"}
          />
        </div>
      </div>
    </>
  );
};
