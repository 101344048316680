import React from "react";
import { Faq } from "../components/Faq";
import { PageLayout } from "../components/PageLayout";
import { faqPageHeroImage } from "../images";

const FaqPage: React.FC = () => {
  return (
    <PageLayout
      heroBackgroundImage={`url(${faqPageHeroImage})`}
      heroOverlayLeadElement="Have Questions?"
      heroOverlaySecondaryElement="Here are the answers to some of the most common"
    >
      <Faq />
    </PageLayout>
  );
};

export default FaqPage;
